import React from 'react'
import Button from './button'
import ImageIcon from './image-icon'

import {useSave} from './object-store'
import genId from '../utils/gen_id'
import {useUpdate} from './object-store'


export default function FileInputButton({model, modelId, xxl}) {
    const ref = React.useRef()
    const save = useSave()
    const update = useUpdate()

    function handleFileSelect(event) {
        const resources = Array.from(event.target.files).map(file => {
            return {
                id: genId(),
                model,
                model_id: modelId,
                file,
            }
        })
        resources.forEach(r => save('resource', r))
        update([{id: modelId, resources: prev => [...resources.map(r => r.id), ...(prev || [])]}])
    }

    return <Button xxl onClick={() => {
        ref.current.click()
    }}>
        <ImageIcon width="16px" height="16px" color="white" />
        <input
            ref={ref}
            style={{display: 'none'}}
            type="file"
            onChange={handleFileSelect}
            multiple
        />
    </Button>
}
