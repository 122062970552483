import React from 'react'

import useCloseOnClickOutside from '../hooks/use-close-on-click-outside'
import bem from '../utils/bem'
import useHash from '../utils/use-hash'
import Button from './button'
import CloseIcon from './close-icon'
import HamburgerIcon from './hamburger-icon'
import {useInit} from './init-context'
import MapIcon from './map-icon'
import {align} from './popup-menu'
import Portal from './portal'
import ProducersIcon from './producers-icon'
import ProductsIcon from './products-icon'
import {useSpinning} from './spinning-context'
import './navigation.scss'


export default function Navigation() {
    const {spinning, setSpinning, error} = useSpinning()
    const is = spinning && spinning.filter(s => !s.error).length > 0

    const [query, setQuery] = React.useState('')
    const [open, setOpen] = React.useState(false)
    const className = bem('navigation')
    const ref = React.useRef()
    const sref = React.useRef()

    useCloseOnClickOutside(open, () => {
        setOpen(false)
    }, sref, ref)

    return <>
        <Button
            onClick={() => {
                setOpen(prev => !prev)
            }}
            innerRef={ref}
            danger={error}
            extraClassName={bem("spinnger").m({danger: error})}
        >
            {is ?
                <div className="spinner spinnger-spinner" />
                : <HamburgerIcon color="white" />
            }
        </Button>
        {open ?
            <Portal parent={ref.current}>
                <Nav
                    innerRef={sref}
                    rref={ref}
                    onRequestClose={() => setOpen(false)} query={query} setQuery={setQuery}
                    spinning={spinning}
                    setSpinning={setSpinning}
                />
            </Portal>
            : null
        }
    </>
}

const ps = {
    width: '24px',
    height: '42px',
    color: '#663299',
}

function Nav({innerRef, rref, onRequestClose, query, setQuery, spinning, setSpinning}) {
    const inputRef = React.useRef()
    const init = useInit()
    const values = ['map', 'producers', 'products']

    const savedQueries = React.useMemo(() => {
        return init.saved_query.filter(s => {
            return score(s.name, query) > 0
        })
    }, [init.saved_query, query])

    React.useEffect(() => {
        if (inputRef.current) {
            setTimeout(() => inputRef.current.select(), 200)
        }
    }, [])

    return <div ref={element => {
        innerRef.current = element
        align(rref.current, element, {dontChangeWidth: true})
    }} className="popup-select nav">
        {/*<input
            type="text"
            value={query}
            onChange={event => setQuery(event.target.value)}
            ref={inputRef}
            placeholder="Search view..."
        />*/}
        <NavSection link="map" onRequestClose={onRequestClose}>
            <MapIcon {...ps} />
        </NavSection>
        <NavSection link="producers" model="producer" savedQueries={savedQueries.filter(s => s.model === 'producer')} onRequestClose={onRequestClose}>
            <ProducersIcon {...ps} />
        </NavSection>
        <NavSection link="products" model="product" savedQueries={savedQueries.filter(s => s.model === 'product')} onRequestClose={onRequestClose}>
            <ProductsIcon {...ps} />
        </NavSection>
        {spinning.length > 0 ?
            <div className="spinning-stuffs">
                {spinning.map(s => {
                    return <SpinningStuff {...s} key={s.id} setSpinning={setSpinning} />
                })}
            </div>
            : null
        }
        <div className="nav__footer">
            <a className="nav__footer__link" href='#queries'>queries</a>
            <a className="nav__footer__link" href='#templates'>templates</a>
            <a className="nav__footer__link" href='#trash'>trash</a>
            <a className="nav__footer__link" href='#multiadd'>multi+</a>
            <a className="nav__footer__link" href='#product-preset'>product+</a>
            <a className="nav__footer__link" href='#products-notes'>products notes</a>
        </div>
    </div>
}

function NavSection({children, link, model, savedQueries, onRequestClose}) {
    const {hash, setHash, params} = useHash()
    return <div>
        <div
            className={bem('nav-section__header').m({active: hash.startsWith('#' + link)})}
            role="button"
            tabIndex="0"
            onClick={() => {
                window.dispatchEvent(new Event('new_query'))
                setHash(link)
                onRequestClose()
            }}
        >
            {children} {link}
        </div>
        {savedQueries && savedQueries.length > 0 ?
            <div className="nav-section__saved-queries">
                {savedQueries.map(s => {
                    return <Button
                        key={s.id}
                        active={s.id === params.query}
                        onClick={() => {
                            onRequestClose()
                            window.dispatchEvent(new Event('new_query'))
                            setHash(link + '&query=' + s.id)}
                        }>
                        {s.name}
                    </Button>
                })}
            </div>
            : null
        }
    </div>
}


function score(text, query) {
    if (!text) {return 0}
    text = text.toLowerCase()
    const parts = query.toLowerCase().split(' ')
    return parts.every(p => text.indexOf(p) !== -1)
}


function SpinningStuff({id, error, message, model, setSpinning}) {
    return <div className="spinning-stuff">
        <div className="spinning-stuff__header">
            <span>{model} - {message}</span>
            <Button
                className="icon-button"
                onClick={() => {
                    setSpinning(prev => {
                        return prev.filter(s => id !== (s.sid || s.id))
                    })
                }}
            >
                <CloseIcon color="--primary-text"/>
            </Button>
        </div>
        {error ?
            <div className="spinning-stuff__error">
                {error.message}
                <pre>
                    {error.traceback}
                </pre>
            </div>
            : null
        }
    </div>
}
