import React from 'react'

import FilterBuilder from './filter-builder'
import get from './get'
import Query from './query'
import RatingQuery from './rating-query'
import SortBuilder from './sort-builder'
import Button from './button'
import FilterIcon from './icons/filter-icon'
import bem from '../utils/bem'

import './query-builder.scss'


export default function QueryBuilder({fields, value, onUpdate, quickFilterFields, separated}) {
    const [open, setOpen] = React.useState(false)

    return <div className="editor query-builder">
        {separated ?
            <>
                <Query
                    name="Producers..."
                    value={get(value, 'producer.fts') || ''}
                    onChange={value => onUpdate(prev => ({...prev, 'producer.fts': value}))}
                />
                <Query
                    name="Products..."
                    value={get(value, 'product.fts') || ''}
                    onChange={value => onUpdate(prev => ({...prev, 'product.fts': value}))}
                />
            </>
            : <Query
                name="Search producers, products..."
                value={get(value, 'fts') || ''}
                onChange={value => onUpdate(prev => ({...prev, 'fts': value}))}
            />
        }
        <div className="spacer">
            <RatingQuery
                value={get(value, 'product_rating.normalized_rating') || '*'}
                onChange={value => onUpdate(prev => ({...prev, 'product_rating.normalized_rating': value}))}
            />
            <Button
                active={open}
                onClick={() => setOpen(prev => !prev)}
            >
                <FilterIcon color="white"/>
            </Button>
        </div>
        <div className={bem('foldable').m({open}).x('spacer spacer--vertical')}>
            <div className="filter-container">
                <div className="filter-container__label">Filter</div>
                <FilterBuilder
                    fields={fields}
                    value={get(value, 'filter')}
                    quickFilterFields={quickFilterFields}
                    onUpdate={u => onUpdate(prev => ({...prev, filter: u(prev.filter)}))}
                />
            </div>
            <div className="filter-container">
                <div className="filter-container__label">Sort</div>
                <SortBuilder
                    fields={fields}
                    value={get(value, 'sort')}
                    onUpdate={u => onUpdate(prev => ({...prev, sort: u(prev.sort)}))}
                />
            </div>
        </div>
    </div>
}
