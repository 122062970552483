import React from 'react'

import Button from './components/button'
import get from './components/get'
import GoogleMapWrapper from './components/google-map-wrapper'
import AddIcon from './components/icons/add-icon'
import LocateIcon from './components/icons/locate-icon'
import {useInit} from './components/init-context'
import LoadMore from './components/load-more'
import {iconPaths} from './components/main-rating'
import Navigation from './components/navigation'
import {objectStoreController} from './components/object-store'
import {useGet} from './components/object-store'
import {useList} from './components/object-store'
import {useMerge} from './components/object-store'
import {useObjectStore} from './components/object-store'
import {useObject} from './components/object-store'
import {useSaveListener} from './components/object-store'
import {useSave} from './components/object-store'
import Paging from './components/paging'
import PopupMenu from './components/popup-menu'
import ProducerEditorModal from './components/producer-editor-modal'
import ProducerList from './components/producer-list'
import {ProducerContent} from './components/producer-view'
import {ProducerFooter} from './components/producer-view'
import {ProducerHeader} from './components/producer-view'
import {useProducerProps} from './components/producer-view'
import {ProductSheet} from './components/product-view'
import Query from './components/query'
import RatingQuery from './components/rating-query'
import Sheet from './components/sheet'
import SortIcon from './components/sort-icon'
import {getColorForTag} from './components/tag'
import useDropResource from './hooks/use-drop-resource'
import useUpdatingRef from './hooks/use-updating-ref'
import useUserCoordinates from './hooks/use-user-coordinates'
import bm from './utils/bm'
import genId from './utils/gen_id'
import translateQuery from './utils/translate-query'
import useHash from './utils/use-hash'
import './app.scss'
import './page-map.scss'
import './sheets.scss'


const interestedInSaved = {
    models: {producer: true},
}


export default function MapPage({}) {
    const {hash, setHash, params, setHashParam} = useHash()

    let selectedProducerId = hash.startsWith('#producer/') ? hash.split('/')[1].split('&')[0] : null
    if (selectedProducerId === 'null') {
        selectedProducerId = null
    }
    let selectedProductId = selectedProducerId ? getProductId(hash) : null

    const [sort, setSort] = React.useState(localStorage.getItem('mapki2.sort') || 'distance')

    React.useEffect(() => {
        localStorage.setItem('mapki2.sort', sort)
    }, [sort])

    const [trigger, setTrigger] = React.useState(0)

    const savedQuery = useObject(get(params, 'query'))

    const [query, setQuery] = React.useState({'producer.fts': '', 'product.fts': '', ratings: '', page: {start: 0, end: 100}})
    const {userCoordinates, refreshUserCoordinates} = useUserCoordinates({
        coordinate_latitudine: 45.4393856,
        coordinate_longitudine: 10.9871104,
    })
    const [panToCoordinates, setPanToCoordinates] = React.useState(null)

    const [merging, setMerging] = React.useState(false)
    const [adding, setAdding] = React.useState(false)

    React.useEffect(() => {
        if (!merging) {
            setSelectedProducers([])
        }
    }, [merging])

    let [referenceCoordinates, setReferenceCoordinates] = useReferenceCoordinates()


    React.useEffect(() => {
        if (!referenceCoordinates) {
            window.location.hash = '#map'
            return
        }
        if (
            userCoordinates &&
            referenceCoordinates.coordinate_latitudine == userCoordinates.coordinate_latitudine &&
            referenceCoordinates.coordinate_longitudine == userCoordinates.coordinate_longitudine
        ) {
            return
        }
        window.location.hash = '#map?rc=' + referenceCoordinates.coordinate_latitudine + ',' + referenceCoordinates.coordinate_longitudine
    }, [referenceCoordinates])
    referenceCoordinates = referenceCoordinates || userCoordinates

    const tSort = React.useMemo(() => {
        if (sort === 'created') {return [{field: 'producer._created_at', dir: 'DESC'}]}
        if (sort === 'updated') {return [{field: 'producer._updated_at', dir: 'DESC'}]}
        if (sort === 'distance') {return [{field: 'producer.distance', from: referenceCoordinates, dir: 'ASC'}]}
        return null
    }, [sort, referenceCoordinates])

    const tQuery = React.useMemo(() => {
        return translateQuery({...query, filter: get(savedQuery, 'query', 'filter')})
    }, [query, savedQuery])


    const active = (sort === 'distance' && !referenceCoordinates) ? false : true

    const {ids, refresh, total, spinning} = useList('producer', {...tQuery, sort: tSort, active})

    const [open, setOpen] = React.useState(localStorage.getItem('mapki2__app__master__open'))
    const [listSheetPosition, setListSheetPosition] = React.useState(localStorage.getItem('mapki2.app.masterPostion'))
    const [productSheetPosition, setProductSheetPosition] = React.useState(selectedProductId && selectedProducerId ? 'open' : 'closed')
    const [producerSheetPosition, setProducerSheetPosition_] = React.useState(selectedProducerId ? 'open' : 'closed')


    function setProducerSheetPosition(...x) {
        setProducerSheetPosition_(...x)
    }

    function toggleOpen() {
        setOpen(prev => {
            localStorage.setItem(localStorage.getItem('mapki2__app__master__open'), !prev)
            return !prev
        })
    }

    function setSelectedProducerId(id) {
        setHash('#producer/' + id, {query: get(params, 'query')})
    }

    useGet('producer', selectedProducerId)
    const selectedProducer = useObject(selectedProducerId)
    useGet('product', selectedProductId)
    const selectedProduct = useObject(selectedProductId)

    const [selectedProducers, setSelectedProducers] = React.useState([])


    React.useEffect(() => {
        document.body.classList.add('no-bounce')
        return () => document.body.classList.remove('no-bounce')
    }, [])

    function toggleProducer(id) {
        setSelectedProducers(p => {
            if (p.indexOf(id) === -1) {
                return [...p, id]
            }
            return p.filter(pid => pid !== id)
        })
    }

    useSaveListener(interestedInSaved, refresh)

    const save = useSave()

    function addProducer(update) {
        const id = genId()
        save('producer', {...update, id})
            .then(() => {
                setHashParam('edit', `producer/${id}`)
            })
    }

    function handleShowProducts(event, products) {
        setProducerSheetPosition('open')
        setHash(`#producer/${products[0].producer_id}/products/${products.map(p => p.id).join(',')}`, {query: get(params, 'query')})
    }

    const queryParam = get(params, 'query')
    const mergeProps = useMerge('producer', refresh)


    return <div className="app">
        <div className="app__map">
            <GoogleMap
                referenceCoordinates={referenceCoordinates}
                producers={ids}
                onSelectProducer={(id, producer) => {
                    if (window.innerWidth < 1040 && producer) {
                        setPanToCoordinates(producer)
                    }
                    setSelectedProducerId(id)
                    setTimeout(() => {
                        setProducerSheetPosition('middle')
                    }, 200)
                }}
                onDoubleClick={addProducer}
                selectedProducerId={selectedProducerId}
                onClick={setReferenceCoordinates}
                userCoordinates={userCoordinates}
                panToCoordinates={panToCoordinates}
                mapRestriction={getMapRestriction()}
            />
            <div className="map__controls">
                <Navigation />
                <button
                    onClick={() => {
                        const prevReferenceCoordinates = referenceCoordinates
                        setPanToCoordinates({...userCoordinates})
                        setReferenceCoordinates(null)
                        refreshUserCoordinates()
                    }}
                    className="button button--square"
                >
                    <LocateIcon color="--on-primary"/>
                </button>
            </div>
        </div>
        <ProductSheet
            product={selectedProduct}
            producer={selectedProducer}
            setHash={setHash}
            producerSheetPosition={producerSheetPosition}
            productSheetPosition={productSheetPosition}
            setProductSheetPosition={setProductSheetPosition}
            params={params}
        />
        <ProducerSheet
            producer={selectedProducer}
            producerSheetPosition={producerSheetPosition}
            setProducerSheetPosition={setProducerSheetPosition}
            setHash={setHash}
            productSheetPosition={productSheetPosition}
            setProductSheetPosition={setProductSheetPosition}
            panTo={setPanToCoordinates}
            params={params}
            limit={false}
            producerLink={`#producer/${get(selectedProducer, 'id')}${queryParam ? ('&query=' + queryParam) : ''}`}
        />
        <Sheet
            position={producerSheetPosition === 'open' ? 'closed' : listSheetPosition}
            onChangePosition={setListSheetPosition}
            extraClassName={bm('list-sheet', {producerSheetPosition, productSheetPosition})}
            movesTextInput
        >
            <Content
                userCoordinates={userCoordinates}
                referenceCoordinates={referenceCoordinates}
                producers={ids}
                setQuery={setQuery}
                sort={sort}
                setSort={setSort}
                query={query}
                setSelectedProducers={setSelectedProducers}
                selectedProducers={selectedProducers}
                onSelectProducer={merging ? toggleProducer : (
                    (id, event) => {
                        setSelectedProducerId(id)
                        setTimeout(() => {
                            setProducerSheetPosition('middle')
                            const producer = get(objectStoreController.state, id)
                            setPanToCoordinates(producer)
                        }, 0)
                    }
                )}
                panTo={setPanToCoordinates}
                total={total}
                page={query.page}
                spinning={spinning}
                mergeProps={mergeProps}
                setProductSheetPosition={setProductSheetPosition}
                setProducerSheetPosition={setProducerSheetPosition}
                onShowProducts={handleShowProducts}
            >
            </Content>
        </Sheet>
        {params.edit ?
            <ProducerEditorModal />
            : null
        }
    </div>

    function getMapRestriction() {
        return `${listSheetPosition}-${producerSheetPosition}-${productSheetPosition}`
    }
}

function ProducerSheet(props) {
    const {hash} = useHash()

    const productsIds = React.useMemo(() => {
        if (hash.indexOf('/products/') !== -1) {
            const raw = hash.split('/products/')[1].split('/')[0].split('&')[0]
            if (raw) {
                return raw.split(',')
            }
        }
        return null
    }, [hash])


    const ps = useProducerProps(props)

    const {dropProps} = useDropResource({
        model: 'producer',
        modelId: get(props.producer, 'id'),
        parentClassName: 'producer-sheet',
    })

    return <Sheet
        extraClassName={bm("producer-sheet", {producerSheetPosition: props.producerSheetPosition, productSheetPosition: props.productSheetPosition})}
        position={props.producerSheetPosition}
        onChangePosition={props.setProducerSheetPosition}
        header
        footer
        id="producer"
        {...dropProps}
    >
        <ProducerHeader {...ps} />
        <ProducerContent {...ps} productsIds={productsIds} />
        <ProducerFooter {...ps} />
    </Sheet>
}


function BottomBar({open, disabled, onCancel, message, onConfirm}) {
    return <div className={bm('app__master__bottom-bar', {open})}>
        <button className="button" onClick={onCancel}>Cancel</button>
        <button disabled={disabled} className="button" onClick={onConfirm}>{message}</button>
    </div>
}


function getLimit(coordinates, query) {
    const longestLine = [query.producer, query.product].reduce((acc , v) => {
        return Math.max(acc, v.split('\n').reduce((acc, l) => Math.max(acc, l.length), 0))
    }, 0)
    if (longestLine >= 3) {
        return 10000
    }
    return 200
}

function Content({
    userCoordinates,
    producers,
    onSelectProducer,
    onRequestOpen,
    setQuery,
    query,
    children,
    total,
    parent,
    page,
    spinning,
    setProducerSheetPosition,
    setProductSheetPosition,
    onShowProducts,
    ...props
}) {
    const selectable = get(props, 'mergeProps', 'mergeActive')
    const {setHash, params} = useHash()

    return <div className="content">
        <div className="query">
            <Query onFocus={onRequestOpen} name="Search producers, products..." value={query['fts']} onChange={producer => setQuery(prev => ({...prev, 'fts': producer}))} />
            {/*<Query onFocus={onRequestOpen} name="Producers..." value={query['producer.fts']} onChange={producer => setQuery(prev => ({...prev, 'producer.fts': producer}))} />*/}
            {/*<Query onFocus={onRequestOpen} name="Products..." value={query['product.fts']} onChange={product => setQuery(prev => ({...prev, 'product.fts': product}))} />*/}
            <RatingQuery
                value={query['product_rating.normalized_rating']} onChange={value => setQuery(prev => ({...prev, 'product_rating.normalized_rating': value}))}
            />
            <div className="query__buttons">
                <PopupMenu
                    onSelect={props.setSort}
                    value={props.sort}
                    closeOnSelect
                    values={[
                        {message: 'distance', value: 'distance'},
                        {message: 'created', value: 'created'},
                        {message: 'updated', value: 'updated'},
                    ]}
                >
                    <SortIcon width="16px" height="16px" color="white" /> {props.sort}
                </PopupMenu>
                <ProducerQuerySwitcher />
                {children}
                <span className="grower" />
                <Paging page={page} total={total} />
            </div>
        </div>
        <ProducerList
            producers={producers}
            userCoordinates={userCoordinates}
            {...props}
            selectable={selectable}
            selected={get(props, 'mergeProps', 'mainToMergeSelection')}
            onSelectProducer={get(props, 'mergeProps', 'mergeActive') ? get(props, 'mergeProps', 'addToMerge') : onSelectProducer}
            toggleMergeActive={get(props, 'mergeProps', 'addToMergeAlways')}
            onShowProduct={(event, p) => {
                setHash(`producer/${p.producer_id}/${p.id}`, {query: get(params, 'query')})
                setTimeout(() => {
                    setProductSheetPosition('open')
                    setProducerSheetPosition('middle')
                }, 0)
            }}
            onShowProducts={onShowProducts}
        />
        <LoadMore
            page={page}
            total={total}
            update={setQuery}
            spinning={spinning}
        />
        {query['fts'] ?
            <NewProducer
                name={query['fts'].split('\n')[0]}
                onCreate={() => setQuery(prev => ({...prev, 'fts': ''}))}
            />
            : null
        }
    </div>
}



export function NewProducer({name, onCreate}) {
    const save = useSave()
    const {setHashParam} = useHash()

    function handleClick() {
        const producer = {id: genId(), name}
        onCreate(producer)
        save('producer', producer)
            .then(() => {
                setHashParam('edit', 'producer/' + producer.id)
            })
    }

    return <div className="new-item-card" onClick={handleClick} role="button" tabIndex={0}>
        <span className="new-item-card__label">create</span>
        <span className="flex1">
            {name}
        </span>
        <AddIcon width="32px" height="32px" color="--primary"/>
    </div>
}




function usePanTo(map, coordinates, mapRestriction) {
    const mapRestrictionRef = useUpdatingRef(mapRestriction)

    React.useEffect(() => {
        if (!map) {return}
        if (!coordinates) {return}
        if (coordinates.coordinate_latitudine === null) {return}
        if (coordinates.coordinate_longitudine === null) {return}
        const lat = coordinates.coordinate_latitudine
        const lng = coordinates.coordinate_longitudine
        const position = {lat, lng,}
        const boudns = {
            east: lng,
            west: lng,
            north: lat,
            south: lat,
        }
        map.panToBounds(boudns, getPadding(mapRestrictionRef.current))
    }, [map, coordinates])

    function getPadding(mapRestriction) {
        const height = window.innerHeight
        const width = window.innerWidth
        const padding = {}
        const w1 = 1040
        if (width > w1) {
            padding.top = height / 2
            padding.bottom = height / 2
            if (mapRestriction === 'null-closed-closed') {
                padding.left = width / 2
                padding.right = width / 2
            }
            else {
                padding.left = w1 + (width - w1) / 2
                padding.right = (width - w1) / 2
            }
        }
        else {
            padding.left = width / 2
            padding.right = width / 2
            if (mapRestriction === 'closed-closed-closed') {
                padding.top = height / 2
                padding.bottom = height / 2
            }
            else {
                padding.top = height / 5
                padding.bottom = height / 5 * 4
            }
        }
        return padding
    }
}



function Map({
    panToCoordinates,
    userCoordinates,
    referenceCoordinates,
    zoom,
    producers,
    onSelectProducer,
    selectedProducerId,
    onClick,
    onDoubleClick,
    children,
    mapRestriction,
}) {
    const ref = React.useRef()
    const [map, setMap] = React.useState()


    usePanTo(map, panToCoordinates, mapRestriction)

    React.useEffect(() => {
        if (!ref.current || map) {return}
        setMap(new window.google.maps.Map(ref.current, {
            center: referenceCoordinates ? {
                lat: referenceCoordinates.coordinate_latitudine,
                lng: referenceCoordinates.coordinate_longitudine,
            } : {
                lat: 45.4393856,
                lng: 10.9871104,
            },
            zoom: zoom || 10,
            gestureHandling: "greedy",
            disableDefaultUI: true,
        }))
    }, [map])

    React.useEffect(() => {
        if (!map) {return}

        let timeouts = []
        let start = null
        let zoom = null
        let clear = false

        function handleMouseDown(a) {
            start = new Date()
            zoom = map.getZoom()
            clear = false
            const timeout = setTimeout(() => {
                onDoubleClick({
                    coordinate_latitudine: a.latLng.lat(),
                    coordinate_longitudine: a.latLng.lng(),
                })
            }, 1000)
            timeouts.push(timeout)
        }

        function handleMouseUp(a) {
            timeouts.forEach(t => clearTimeout(t))
            timeouts = []
            if (new Date() - start < 400 && zoom === map.getZoom() && !clear) {
                onClick({
                    coordinate_latitudine: a.latLng.lat(),
                    coordinate_longitudine: a.latLng.lng(),
                })
            }
        }

        function handleDragStart() {
            clear = true
            timeouts.forEach(t => clearTimeout(t))
            timeouts = []
        }

        const listener0 = map.addListener('mousedown', handleMouseDown)
        const listener1 = map.addListener('mouseup', handleMouseUp)
        const listener2 = map.addListener('dragstart', handleDragStart)
        const listener3 = map.addListener('resize', handleDragStart)
        const listener4 = map.addListener('tilt_changed', handleDragStart)
        const listener5 = map.addListener('bounds_changed', handleDragStart)
        return () => {
            window.google.maps.event.removeListener(listener0)
            window.google.maps.event.removeListener(listener1)
            window.google.maps.event.removeListener(listener2)
            window.google.maps.event.removeListener(listener3)
            window.google.maps.event.removeListener(listener4)
            window.google.maps.event.removeListener(listener5)
        }
    }, [map])

    return <div ref={ref} id="map" className="map-container">
        {producers.map(id => {
            return <ProducerMapMarker
                id={id}
                map={map}
                onSelect={onSelectProducer}
                active={selectedProducerId === id}
                key={id}
            />
        })}
        {userCoordinates ?
            <ReferenceMapMarker map={map} coordinates={userCoordinates} active={!referenceCoordinates} onClick={onClick} />
            : null
        }
        {referenceCoordinates ?
            <ReferenceMapMarker map={map} coordinates={referenceCoordinates} active />
            : null
        }
    </div>
}

function ReferenceMapMarker({map, coordinates, active, onClick}) {
    React.useEffect(() => {
        if (!coordinates) {return}
        if (!map) {return}
        const position = {lat: coordinates.coordinate_latitudine, lng: coordinates.coordinate_longitudine}
        const marker = new window.google.maps.Marker({
            position,
            icon: getIcon(active),
            map,
        })
        if (onClick) {
            marker.addListener('click', () => {
                onClick(coordinates)
            })
        }

        return () => marker.setMap(null)
    }, [map, active, coordinates])

    function getIcon(active) {
        return {
            path: 'M35.2745401,76.9537466 C23.5243881,71.8507861 14.9408366,60.8270569 13.2891064,47.6477797 C13.0996379,46.4659126 13,45.2514641 13,44 C13,43.8330728 13.0011054,43.6664039 13.0033093,43.5 C13.0011054,43.3335961 13,43.1669272 13,43 C13,22.5654643 29.5654643,6 50,6 C70.4345357,6 87,22.5654643 87,43 C87,43.1669272 86.9988946,43.3335961 86.9966907,43.5 C86.9988946,43.6664039 87,43.8330728 87,44 C87,45.2514641 86.9003621,46.4659126 86.7108936,47.6477797 C85.0591634,60.8270569 76.4756119,71.8507861 64.7254599,76.9537466 C59.0674536,82.3778153 53.582827,88.1327525 50,95 C46.417173,88.1327525 40.9325464,82.3778153 35.2745401,76.9537466 L35.2745401,76.9537466 Z',
            fillColor: active ? "#cc48de" : "gray",
            fillOpacity: active ? 0.7 : 0.6,
            strokeColor: 'white',
            strokeWeight: 2,
            scale: 0.5,
            anchor: new window.google.maps.Point(50, 100)
        }
    }

    return null
}


function ProducerMapMarker({map, id, active, onSelect}) {
    const producer = useObject(id)
    const {state} = useObjectStore()
    React.useEffect(() => {
        if (!producer) {return}
        if (!producer.coordinate_latitudine || !producer.coordinate_longitudine) {
            return
        }
        const tags = producer.tags.map(id => state[id].tag)
        const position = {lat: producer.coordinate_latitudine, lng: producer.coordinate_longitudine}
        if (!position) {return}
        const icon = {
            path: getPath(producer, tags),
            fillColor: getColor(tags),
            anchor: new window.google.maps.Point(50, 50),
            fillOpacity: 1,
            // fillOpacity: entry.matchesQuery ? 1 : 0.2,
            strokeColor: 'white',
            strokeWeight: active ? 2 : 1,
            scale: active ? 0.35 : 0.2,
        }
        const marker = new window.google.maps.Marker({
            map,
            position,
            icon: icon,
            zIndex: active ? 999999 : undefined,
        })
        marker.addListener('click', () => {
            onSelect(producer.id, producer)
        })
        return () => marker.setMap(null)
    }, [map, producer, active])

    return null

    function getColor(tags) {
        const color = tags
            .filter(t => t !== '#' && t !== 'TODO')
            .map(getColorForTag)
            .filter(c => {
                return c !== getComputedStyle(document.documentElement).getPropertyValue('--primary')
            })[0]


        return color || 'black'
    }

    function getPath(producer, tags) {
        const hash = tags.filter(t => t === '#')[0]
        if (hash) {
            return iconPaths.hash
        }
        const todo = tags.filter(t => t === 'TODO')[0]
        const done = todo && producer.rating
        if (done) {
            return iconPaths['todoDone' + producer.rating]
        }
        if (todo) {
            return iconPaths.todo
        }
        return iconPaths['orating' + producer.rating] || iconPaths.oratingNull
    }

    function getLabel(producer) {
        const fontSize = active ? '1.4rem' : '12px'
        if (producer.rating === null) {
            return null
        }
        return {fontSize, color: 'white'}
    }
}




function useReferenceCoordinates(initial) {
    const [value, setValue] = React.useState(() => {
        const hash = window.location.hash
        if (!hash) {return}
        if (hash.indexOf('rc=') !== -1) {
            const raw_coordinates = hash.split('rc=')[1].split('&')[0]
            const [coordinate_latitudine, coordinate_longitudine] = raw_coordinates.split(',').map(c => parseFloat(c))
            const init = {coordinate_latitudine, coordinate_longitudine}
            return init
        }
    })
    return [value, setValue]
}

function GoogleMap(props) {
    return <GoogleMapWrapper>
        <Map {...props} />
    </GoogleMapWrapper>
}



function getProductId(hash) {
    const d = hash.split('/')[2]
    if (d) {
        return d.split('&')[0]
    }
    return null
}


function ProducerQuerySwitcher() {
    const init = useInit()
    const savedQueries = React.useMemo(() => {
        return init.saved_query.filter(s => {
            return s.model === 'producer'
        })
    }, [init.saved_query])

    const {hash, setHashParam, params} = useHash()


    return <>
        {savedQueries.map(s => {
            return <Button
                key={s.id}
                title={s.name}
                active={s.id === params.query}
                onClick={() => {
                    if (s.id === params.query) {
                        setHashParam('query', null)
                    }
                    else {
                        setHashParam('query', s.id)}
                    }
                }
                active={params.query === s.id}
            >
                {getShortName(s.name)}
            </Button>
        })}
    </>

    function getShortName(name) {
        const [a, b] = name.split(' | ')
        return a
    }
}
