import React from 'react'

import Svg from '../svg'


export default function FilterIcon({width, height, color, ...props}) {
    color = getComputedStyle(document.documentElement)
        .getPropertyValue(color || '--content-color') || color

    return <Svg width={width || '16px'} height={height || '16px'} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="filter" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <path d="M3,15 L3,6 L97,6 L97,15 L61,51 L61,69.8208944 L39,91 L39,51 L3,15 Z" id="Combined-Shape" fill={color || "white"}></path>
    </g>
    </Svg>
}
