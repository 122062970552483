import React from 'react'

import bm from '../utils/bm'
import './button.scss'


export default function Button({children, innerRef, onNull, round, square, danger, active, extraClassName, valueOption, xxl, ...props}) {
    return <button className={bm('button', {active, danger, square, round, xxl}, extraClassName)} {...props} ref={innerRef} tabIndex={0} role="button">
        {children}
    </button>
}
