import React from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import CloseIcon from './close-icon'

import './query.scss'



export default function Query({name, value, onChange, onFocus}) {
    const [value_, setValue_] = React.useState(null)
    const ref = React.useRef()

    function onFocus(event) {
        // setValue_(value)
    }

    function onBlur(event) {
        onChange(event.target.value)
        setValue_(null)
    }

    function clear(event) {
        console.log('clear')
        setValue_('')
        onChange('')
        if (ref.current) {
            ref.current.focus()
        }
    }

    return <div className="query__item">
        <TextareaAutosize
            value={value_ === null ? value : value_}
            onChange={event => setValue_(event.target.value)}
            onFocus={onFocus}
            onBlur={onBlur}
            placeholder={name}
            onFocus={onFocus}
            ref={ref}
        />
        <button onClick={clear} className="icon-button">
            <CloseIcon color="--primary" />
        </button>
    </div>
}
